import request from './request';
import { API_URL } from "../env";

export const fetchRegionListEP = () => {
    return request({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        url: `${API_URL()}/region/list`
    });
};
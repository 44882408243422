import 'react-datepicker/dist/react-datepicker.css';
import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';

import DatePicker, { registerLocale } from 'react-datepicker';
import sr from 'date-fns/locale/sr-Latn';
registerLocale('rs', sr);

const SurveyInfo = (props) => {
    const [minTime, setMinTime] = useState([new Date(), new Date(new Date().setMinutes(new Date().getMinutes() + 15))]);
    const [maxTime] = useState(new Date(new Date().setHours(23, 59, 0, 0)));
    const { from, to, setSurveyName, setSurveyFrom, setSurveyTo } = props;

    const checkIfToday = (date, setMethod, event) => {
        const isToday = new Date();

        if (
            date.getFullYear() === isToday.getFullYear() &&
            date.getMonth() === isToday.getMonth() &&
            date.getDate() === isToday.getDate()
        ) {
            // setMinTime([new Date(), new Date(new Date().setMinutes(new Date().getMinutes() + 15))]);

            if (event !== undefined) {
                setMethod(new Date(new Date().setHours(new Date().getHours(), new Date().getMinutes() + 15, 0, 0)));
            } else {
                setMethod(date);
            }
        } else {
            setMinTime([new Date(new Date().setHours(0, 0, 0, 0)), new Date(new Date().setHours(0, 0, 0, 0))]);
            setMethod(date);
        }
    };

    return (
        <Container className="bg-white page-wrapper page-wrapper_steps">
            <h2 className="text-center">Podaci o anketi</h2>
            <div id="survey-data-container">
                <div className="mx-0 my-3 pl-3 pr-3 w-100 align-items-end">
                    <div className="text-center">Naziv ankete:</div>
                    <DebounceInput
                        id="survey-name-input"
                        minLength={2}
                        debounceTimeout={300}
                        value={props.name}
                        onChange={(event) => setSurveyName(event.target.value)}
                    />
                </div>
                <Row className="w-100 m-0 justify-content-between">
                    {[
                        {
                            name: 'Od',
                            minDate: new Date(),
                            selected: from,
                            setMethod: setSurveyFrom,
                            minVreme: minTime[0],
                            timeIntervals: 10
                        },
                        {
                            name: 'Do',
                            minDate: from,
                            selected: to,
                            setMethod: setSurveyTo,
                            minVreme: minTime[1],
                            timeIntervals: 10
                        }
                    ].map((period, key) => (
                        <Col md={6} className="mx-0 my-3 align-items-end" key={key}>
                            <div className="text-center">{period.name}:</div>

                            <DatePicker
                                locale="rs"
                                minDate={period.minDate !== null ? period.minDate : new Date()}
                                dateFormat="dd.MM.yyyy. HH:mm"
                                selected={period.selected}
                                onChange={(date, event) => {
                                    checkIfToday(date, period.setMethod, event);
                                }}
                                className="pickSurveyDate"
                                timeCaption="Vreme"
                                minTime={period.minVreme}
                                maxTime={maxTime}
                                timeIntervals={period.timeIntervals}
                                showTimeSelect
                            />
                        </Col>
                    ))}
                </Row>
            </div>

            <div className="mt-4 text-right">
                <Button onClick={() => props.handleNewSurvey()} className="new-survey-btn bg-red">
                    NAPRAVI
                </Button>
            </div>
        </Container>
    );
};

export default SurveyInfo;

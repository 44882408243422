import axios from 'axios';

const request = (props) => {
    const options = {
        ...props,
        headers: {
            ...props.headers,
            'Content-Type': (props.headers && props.headers['Content-Type']) || 'application/x-www-form-urlencoded'
        }
    };
    return axios(options)
        .then((response) => {
            if (response.data.success === false) {
                throw response.data;
            }
            return response.data;
        })
        .catch((err) => {
            if (err.response) {
                switch (err.response.status) {
                    case 403:
                        // window.location.replace('/');
                        throw err.response.data.error;
                    default:
                        //console.error(err.response.status);
                        //console.error(err.response.data.error);
                        throw err.response.data.error || err.response.data.errors;
                    //break;
                }
            } else {
                throw err;
            }
        });
};
export default request;

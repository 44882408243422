import {
    SELECTED_SURVEY,
    FETCH_SURVEYS,
    FETCH_SUCCESS_SURVEYS,
    FETCH_ERROR_SURVEYS,
    DELETE_SURVEY,
    DELETE_SURVEY_ERROR,
    DELETE_SURVEY_SUCCESS
} from './actionType';

export const setSelectedSurvey = (selectedSurvey) => {
    return { type: SELECTED_SURVEY, payload: selectedSurvey };
};

export const fetchSurveys = () => {
    return { type: FETCH_SURVEYS };
};

export const fetchSurveysSuccess = (data) => {
    return { type: FETCH_SUCCESS_SURVEYS, payload: data };
};

export const fetchSurveysError = (data) => {
    return { type: FETCH_ERROR_SURVEYS, payload: data };
};

export const deleteSurvey = (id) => {
    return { type: DELETE_SURVEY, payload: id };
};

export const deleteSurveySuccess = (data) => {
    return { type: DELETE_SURVEY_SUCCESS, payload: data };
};

export const deleteSurveyError = (data) => {
    return { type: DELETE_SURVEY_ERROR, payload: data };
};

import React, { useEffect } from 'react';
import { Container, Row, Col, Input, Button, Table } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchKeys, addKey, deleteKey } from '../store/actions';

const Keys = (props) => {
    const { keysList, addKey, deleteKey } = props;

    useEffect(() => {
        props.fetchKeys();
    }, []);

    const handleNewKey = () => {
        var keyName = document.getElementsByName('keyNameInput')[0].value;
        var apiKey = document.getElementsByName('apiKeyInput')[0].value;

        if (keyName !== '' && apiKey !== '') {
            addKey(keyName, apiKey);
            document.getElementsByName('keyNameInput')[0].value = '';
            document.getElementsByName('apiKeyInput')[0].value = '';
        } else alert('Morate uneti sva polja!');
    };

    const handleDeleteKey = (id) => {
        deleteKey(id);
    };

    return (
        <Container className="bg-white page-wrapper">
            <h1 className="text-center mb-5">Ključevi</h1>
            <Button className="my-3" onClick={() => props.history.push('/surveys')}>
                Vrati se na ankete
            </Button>
            <Row className="m-0 w-100">
                <Col lg={6}>
                    <Table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Ime ključa</th>
                                <th>API ključa</th>
                                <th className="text-center">-</th>
                            </tr>
                        </thead>
                        <tbody>
                            {keysList &&
                                keysList.map((key, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}.</td>
                                        <td>{key.name}</td>
                                        <td>{key.apiKey}</td>
                                        <td>
                                            <Button
                                                color="primary"
                                                className="w-100"
                                                onClick={() => handleDeleteKey(key.id)}>
                                                Obriši ključ
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Col>
                <Col lg={6}>
                    <Input name="keyNameInput" className="mb-3" placeholder="Ime ključa" />
                    <Input name="apiKeyInput" className="mb-3" placeholder="API ključa" />
                    <Button className="w-100" onClick={() => handleNewKey()}>
                        Dodaj ključ
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state) => {
    if (state.KeysReducer.initialized) {
        return {
            keysList: state.KeysReducer.keysList.data
        };
    }
    return {
        keysList: state.KeysReducer.keysList
    };
};

export default connect(mapStateToProps, { fetchKeys, addKey, deleteKey })(withRouter(Keys));

import {
    FETCH_KEYS,
    FETCH_SUCCESS_KEYS,
    FETCH_ERROR_KEYS,
    ADD_KEY,
    FETCH_NEW_KEY_SUCCESS,
    FETCH_NEW_KEY_ERROR,
    DELETE_KEY,
    DELETE_KEY_SUCCESS,
    DELETE_KEY_ERROR
} from './actionType';

const initialState = {
    initialized: false,
    loading: false,
    error: undefined,
    keysList: [],
    newKey: null
};

const keys = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_KEYS:
            return { ...state, loading: true };
        case FETCH_SUCCESS_KEYS:
            return { ...state, loading: false, initialized: true, keysList: action.payload };
        case FETCH_ERROR_KEYS:
            return { ...state, loading: false, error: action.payload };
        case ADD_KEY:
            return {
                ...state
            };
        case FETCH_NEW_KEY_SUCCESS:
            return {
                ...state,
                loading: true,
                keysList: {
                    ...state.keysList,
                    data: [
                        ...state.keysList.data,
                        {
                            id: action.payload.data.id,
                            name: action.payload.data.name,
                            apiKey: action.payload.data.apiKey
                        }
                    ]
                }
            };
        case FETCH_NEW_KEY_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case DELETE_KEY:
            return {
                ...state
            };
        case DELETE_KEY_SUCCESS:
            return {
                ...state,
                loading: true,
                keysList: {
                    ...state.keysList,
                    data: state.keysList.data.filter((e) => e.id !== action.payload.payload)
                }
            };
        case DELETE_KEY_ERROR:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

export default keys;

import {
    SELECTED_SURVEY,
    FETCH_SURVEYS,
    FETCH_SUCCESS_SURVEYS,
    FETCH_ERROR_SURVEYS,
    DELETE_SURVEY,
    DELETE_SURVEY_SUCCESS,
    DELETE_SURVEY_ERROR
} from './actionType';

const initialState = {
    initialized: false,
    loading: false,
    error: undefined,
    surveyList: [],
    selectedSurvey: ''
};

const surveys = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SURVEYS:
            return { ...state, loading: true };
        case FETCH_SUCCESS_SURVEYS:
            return { ...state, loading: false, initialized: true, surveyList: action.payload };
        case FETCH_ERROR_SURVEYS:
            return { ...state, loading: false, error: action.payload };
        case SELECTED_SURVEY:
            return { ...state, selectedSurvey: action.payload };
        case DELETE_SURVEY:
            return { ...state };
        case DELETE_SURVEY_SUCCESS:
            return { ...state, loading: true, surveyList: state.surveyList.filter((s) => s._id !== action.payload) };
        case DELETE_SURVEY_ERROR:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export default surveys;

export const FETCH_KEYS = 'FETCH_KEYS';
export const FETCH_SUCCESS_KEYS = 'FETCH_SUCCESS_KEYS';
export const FETCH_ERROR_KEYS = 'FETCH_ERROR_KEYS';

export const ADD_KEY = 'ADD_KEY';
export const FETCH_NEW_KEY_SUCCESS = 'FETCH_NEW_KEY_SUCCESS';
export const FETCH_NEW_KEY_ERROR = 'FETCH_NEW_KEY_ERROR';

export const DELETE_KEY = 'DELETE_KEY';
export const DELETE_KEY_SUCCESS = 'DELETE_KEY_SUCCESS';
export const DELETE_KEY_ERROR = 'DELETE_KEY_ERROR';
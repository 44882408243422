import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Container, Button } from 'reactstrap';

import { login } from '../store/actions';

const LoginPage = (props) => {
    const { error } = props;

    const handleValidUser = (event, values) => {
        props.login(values);
    };

    return (
        <Container className="page-wrapper bg-white">
            <h1 className="text-center mb-5">Anketa Admin Panel</h1>
            <AvForm className="mx-auto content-width" onValidSubmit={handleValidUser}>
                {error && <p className="text-center">{error}</p>}
                <div className="inputHeight">
                    <AvField
                        name="username"
                        label="Korisničko ime"
                        required
                        errorMessage={'Unesite svoje korisničko ime'}
                    />
                </div>
                <div className="inputHeight">
                    <AvField
                        name="password"
                        label="Lozinka"
                        type="password"
                        required
                        errorMessage={'Unesite lozinku'}
                    />
                </div>

                <Button type="submit" className="bg-red">
                    Uloguj se
                </Button>
            </AvForm>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.LoginStore.loading,
        success: state.LoginStore.success,
        error: state.LoginStore.error
    };
};

export default connect(mapStateToProps, { login })(withRouter(LoginPage));

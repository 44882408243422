import {
	FETCH_KEYS,
	FETCH_SUCCESS_KEYS,
	FETCH_ERROR_KEYS,
	ADD_KEY,
	FETCH_NEW_KEY_SUCCESS,
	FETCH_NEW_KEY_ERROR,
	DELETE_KEY,
	DELETE_KEY_SUCCESS,
	DELETE_KEY_ERROR
} from './actionType';

export const fetchKeys = () => {
	return { type: FETCH_KEYS };
};

export const fetchKeysSuccess = (data) => {
	return { type: FETCH_SUCCESS_KEYS, payload: data };
};

export const fetchKeysError = (data) => {
	return { type: FETCH_ERROR_KEYS, payload: data };
};

export const addKey = (name, apiKey) => {
	return {
		type: ADD_KEY,
		payload: {
			name,
			apiKey
		}
	};
};

export const fetchNewKeySuccess = (data) => {
	return {
		type: FETCH_NEW_KEY_SUCCESS,
		payload: data
	};
};

export const fetchNewKeyError = (data) => {
	return { type: FETCH_NEW_KEY_ERROR, payload: data };
};

export const deleteKey = (id) => {
	return {
		type: DELETE_KEY,
		payload: id
	};
};

export const deleteKeySuccess = (id) => {
	return { type: DELETE_KEY_SUCCESS, payload: id };
};

export const deleteKeyError = (data) => {
	return { type: DELETE_KEY_ERROR, payload: data };
};

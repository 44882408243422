import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from './App.js';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import {API_URL} from './env'
import store from './store';

setImmediate(async () => {
	let logged = await axios
		.post(`${API_URL()}/auth/jwt/verify`, {}, { withCredentials: true})
		.then((res) => {
			if (res.data.success === true) {
				return true;
			}
			return false;
		})
		.catch((err) => {
			return false;
		});
	const app = (
		<Provider store={store}>
			<App logged={logged} />
		</Provider>
	);

	ReactDOM.render(app, document.getElementById('root'));
});

import { React } from 'react';
import { Container, Button, Row, Col } from 'reactstrap';
import Select from 'react-select';

const Questions = (props) => {
    const { questionSection, nextStep, actions, title, bottomText, keyVal,selectQuestionTypes } = props;

    return (
        <Container key={keyVal} className="page-wrapper page-wrapper page-wrapper_scroll page-wrapper_steps bg-white">
            <h1 className="text-center mb-5">{title}</h1>
            {questionSection.map((section, sectionIndex) => {
                return (
                    <div key={sectionIndex}>
                        <Row className="align-items-center justify-content-between mb-4 mt-5">
                            <Col md={9} className="d-flex align-items-center">
                                <div className="q-number_main text-red text-right">{sectionIndex + 1}</div>
                                <input
                                    className="mb-0 ml-2 w-100 custom-input"
                                    name="questionTitle"
                                    placeholder="Unesite pitanje"
                                    value={section.questionTitle}
                                    onChange={(e) => actions.setQuestionText(e.target.value, sectionIndex)}
                                />
                            </Col>
                            <Col md={3}>
                                <Select
                                    placeholder="Tip odgovora"
                                    value={selectQuestionTypes.find((e) => e.value === section.questionType)}
                                    options={selectQuestionTypes}
                                    onChange={(e) => actions.setQuestionType(e.value, sectionIndex)}
                                />
                            </Col>
                            {section.questionType === 'CM' && (
                                <Col md={9} className="d-flex align-items-center mt-3 second-choice">
                                    <div className="choice-check">
                                        <input
                                            className="mr-2"
                                            type="checkbox"
                                            checked={section.secondOption}
                                            onClick={() => actions.toggleSecondOption(sectionIndex)}
                                        />
                                        Drugi izbor
                                    </div>
                                    <div className="choice-input">
                                        {section.secondOption && (
                                            <input
                                                type="text"
                                                className="mb-0 w-100 custom-input"
                                                placeholder="Unesite pitanje za drugi izbor"
                                                value={section.secondOptionTitle}
                                                onChange={(e) =>
                                                    actions.setSecondOptionText(e.target.value, sectionIndex)
                                                }
                                            />
                                        )}
                                    </div>
                                </Col>
                            )}
                        </Row>
                        {section.questions.map((question, questionIndex) => {
                            return (
                                <div key={questionIndex}>
                                    <Row className="align-items-center justify-content-between mb-2">
                                        <Col md={9} className="d-flex align-items-center">
                                            <div className="q-number text-right">{`${sectionIndex + 1}.${
                                                questionIndex + 1
                                            }`}</div>
                                            <input
                                                className="mb-0 ml-2 w-100 custom-input"
                                                name="questionText"
                                                placeholder="Unesite potpitanje"
                                                index={questionIndex}
                                                value={question.questionText}
                                                onChange={(e) =>
                                                    actions.setSubQuestionText(
                                                        e.target.value,
                                                        sectionIndex,
                                                        questionIndex
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col md={2} className="d-flex justify-content-end">
                                            {section.questions.length - 1 === questionIndex && (
                                                <Button
                                                    className="bg-red ml-2"
                                                    onClick={() => actions.addSubQuestion(sectionIndex)}>
                                                    +
                                                </Button>
                                            )}
                                            {section.questions.length !== 1 && (
                                                <Button
                                                    className="bg-red ml-2"
                                                    onClick={() =>
                                                        actions.removeSubQuestion(sectionIndex, questionIndex)
                                                    }>
                                                    -
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            );
                        })}
                        <Button
                            className="bg-red d-block ml-auto mt-4"
                            onClick={() => actions.removeQuestion(section, sectionIndex)}>
                            Obriši pitanje
                        </Button>
                    </div>
                );
            })}
            <div className="text-right mt-5">
                <Button className="bg-red" onClick={actions.addQuestion}>
                    Dodaj novo pitanje
                </Button>
            </div>
            <div className="text-center mt-5">
                <Button className="get-questions-btn" onClick={() => nextStep()}>
                    {bottomText}
                </Button>
            </div>
        </Container>
    );
};

export default Questions;

import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_KEYS, ADD_KEY, DELETE_KEY } from './actionType';
import {
	fetchKeysSuccess,
	fetchKeysError,
	fetchNewKeySuccess,
	fetchNewKeyError,
	deleteKeySuccess,
	deleteKeyError
} from './action';

import { fetchListKeysEP, fetchNewKeyEP, deleteKeyEP } from '../../../api/keys';

function* fetchKeysData() {
	try {
		let response = yield call(fetchListKeysEP);
		if (response) {
			yield put(fetchKeysSuccess(response));
		} else {
			yield put(fetchKeysError(response.message));
		}
	} catch (error) {
		yield put(fetchKeysError(error.message));
	}
}

function* fetchNewKeyData(payload) {
	try {
		let response = yield call(fetchNewKeyEP, payload);
		if (response) {
			yield put(fetchNewKeySuccess(response));
		} else {
			yield put(fetchNewKeyError(response.message));
		}
	} catch (error) {
		yield put(fetchNewKeyError(error.message));
	}
}

function* deleteKeyData(data) {
	try {
		let response = yield call(deleteKeyEP, data);
		if (response) {
			yield put(deleteKeySuccess(data));
		} else {
			yield put(deleteKeyError(response.message));
		}
	} catch (error) {
		yield put(deleteKeyError(error.message));
	}
}

export function* watchFetchKeysData() {
	yield takeEvery(FETCH_KEYS, fetchKeysData);
}

export function* watchFetchNewKeyData() {
	yield takeEvery(ADD_KEY, fetchNewKeyData);
}

export function* watchDeleteKey() {
	yield takeEvery(DELETE_KEY, deleteKeyData);
}

function* keysSaga() {
	yield all([ fork(watchFetchKeysData), fork(watchFetchNewKeyData), fork(watchDeleteKey) ]);
}

export default keysSaga;

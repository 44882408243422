import request from './request';
import { API_URL } from "../env";

export const fetchListSurveysEP = () => {
    return request({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        url: `${API_URL()}/survey/list`
    });
};

export const addNewSurveyEP = (data) => {
    return request({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        url: `${API_URL()}/survey/create`,
        data: {
            name: data.payload.name,
            from: data.payload.from,
            to: data.payload.to,

            townships: data.payload.townships,
            questionGroups: data.payload.questionGroups,
            specialQuestionGroups: data.payload.specialQuestionGroups
        }
    });
};

export const deleteSurveyEP = (data) => {
    return request({
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        url: `${API_URL()}/survey/delete/` + data.payload
    });
};

import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Input, Button } from 'reactstrap';
import { predefineAgeNumOfPpl } from '../data';

const Regions = (props) => {
    var { ages, selectedRegions, setSelectedRegions, updateSelectedRegions, removeSelectedRegions } = props;

    const [currentRegion, setCurrentRegion] = useState(selectedRegions.length > 0 ? selectedRegions[0].region : -1);
    const currentAgeInput = useRef(
        selectedRegions.length > 0
            ? selectedRegions.findIndex((s) => {
                  return s;
              })
            : -1
    );

    var allInputsM = document.getElementsByName(`agesM${currentAgeInput.current}`);
    var allInputsF = document.getElementsByName(`agesF${currentAgeInput.current}`);

    // render list of townships
    const List = ({ town }) => {
        // selectable region
        const regionItem = (index, regionName) => {
            return (
                <div
                    key={index}
                    onClick={() => handleSelectedRegions(index, regionName.id, false)}
                    onDoubleClick={() => handleSelectedRegions(index, regionName.id, true)}
                    className={`region-name p-1 cursor-pointer ${
                        selectedRegions.length > 0 &&
                        selectedRegions.filter((e) => e.region === regionName.id).length > 0
                            ? currentRegion === regionName.id
                                ? 'bg-white text-blue'
                                : 'bg-grey text-blue'
                            : 'text-white'
                    }`}>
                    {regionName.name}
                </div>
            );
        };

        // non selectable region
        const renderItem = (items) => {
            return (
                <div>
                    {items.map((i, key) => {
                        return (
                            <div className={`region-name p-2`} key={key}>
                                {i.regions !== undefined && <div className="text-grey">{i.name}</div>}

                                {i.regions === undefined ? regionItem(key, i) : renderItem(i.regions)}
                            </div>
                        );
                    })}
                </div>
            );
        };

        // first region
        for (var i = 0; i < town.length; i++) {
            return (
                <div key={i}>
                    {town.length > 1 && <div className="text-grey">{town[i].name}</div>}
                    {town[i].regions === undefined ? regionItem(i, town[i]) : renderItem(town[i].regions)}
                </div>
            );
        }
        return null;
    };

    // get value from age inputs
    const getInputValue = () => {
        var array = [];
        for (var i = 0; i < allInputsM.length; i++) {
            if (allInputsM[i].value === '') {
                //alert('Moraju biti popunjena sva polja');
                return;
            }
            array.push({
                fromAge: parseInt(allInputsM[i].dataset.from),
                toAge: parseInt(allInputsM[i].dataset.to),
                maleNumOfPpl: parseInt(allInputsM[i].value),
                femaleNumOfPpl: parseInt(allInputsF[i].value)
            });
        }
        return array;
    };

    // set value to age inputs
    const setInputValue = (regionsValue = undefined) => {
        if (regionsValue !== undefined) {
            if (regionsValue.ageGroups !== undefined)
                for (var j = 0; j < allInputsM.length; j++) {
                    allInputsM[j].value = regionsValue.ageGroups[j].maleNumOfPpl;
                    allInputsF[j].value = regionsValue.ageGroups[j].femaleNumOfPpl;
                }
        }
    };

    // add, remove and select clicked region
    const handleSelectedRegions = (index = currentAgeInput.current, r = currentRegion, isDoubleClick = false) => {
        if (isDoubleClick) {
            currentAgeInput.current = -1;
            setCurrentRegion('');
            removeSelectedRegions(r);
        } else {
            currentAgeInput.current = index;
            setCurrentRegion(r);
        }
    };

    // add region to selected array, update old values
    const handleAgesGroups = (handleUpdate, r = currentRegion) => {
        var clickedRegion = selectedRegions.filter((a) => {
            return a.region === r;
        });
        var defaultAgeValues = predefineAgeNumOfPpl[r];

        if (clickedRegion.length === 0) {
            for (var j = 0; j < allInputsM.length; j++) {
                allInputsM[j].value = defaultAgeValues !== undefined ? defaultAgeValues[j].maleNumOfPpl : 1; // allInputsM[j].defaultValue;
                allInputsF[j].value = defaultAgeValues !== undefined ? defaultAgeValues[j].femaleNumOfPpl : 1; // allInputsF[j].defaultValue;
            }

            var temp = {
                region: r,
                ageGroups: getInputValue()
            };

            setSelectedRegions(temp);
        } else {
            if (handleUpdate === true) {
                updateSelectedRegions(r, getInputValue());
                return;
            }

            setInputValue(selectedRegions.find((a) => a.region === r));
        }
    };

    // when change value in input or change region
    useEffect(() => {
        if (currentAgeInput.current > -1) {
            handleAgesGroups(false, currentRegion);
        }
    }, [currentAgeInput, currentRegion]);

    // when
    useEffect(() => {
        setInputValue(selectedRegions);
    }, [selectedRegions]);

    // useEffect(() => {});

    return (
        <Container className="bg-white page-wrapper page-wrapper_steps page-wrapper_steps">
            <Row className="w-100 m-0">
                <Col md={5} className="bg-red regions-list p-4">
                    {props.township && <List town={props.township.data} />}
                </Col>
                {currentAgeInput.current > -1 && (
                    <InputWrapper
                        ages={ages}
                        currentRegion={currentRegion}
                        currentAgeInput={currentAgeInput}
                        handleAgesGroups={handleAgesGroups}
                    />
                )}
            </Row>
            <Button className="get-questions-btn" onClick={() => props.nextStep()}>
                Pitanja
            </Button>
        </Container>
    );
};

const InputWrapper = (props) => {
    const { ages, currentRegion, currentAgeInput, handleAgesGroups } = props;

    const handleOnlyNumber = (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <Col md={7} className={`bg-grey p-4 pt-2 ages-list`}>
            <div className="d-flex justify-content-end">
                <div id="label-male">Muškarci</div>
                <div id="label-female">Žene</div>
            </div>
            <Form className={`${currentRegion}`} onChange={() => handleAgesGroups(true)}>
                {ages.map((a, index) => (
                    <div
                        className={`d-flex justify-content-between align-items-center my-4 font-25 ages-container text-white`}
                        key={index}>
                        <div>
                            {a.from}
                            {a.to ? ' - ' + a.to : '+'}
                        </div>

                        <div className="d-flex justify-content-around">
                            <Input
                                type="number"
                                onKeyPress={(event) => handleOnlyNumber(event)}
                                className={`number-of-ppl-input text-white`}
                                name={`agesM${currentAgeInput.current}`}
                                min="0"
                                data-from={`${a.from}`}
                                data-to={`${a.to}`}
                            />
                            <Input
                                type="number"
                                onKeyPress={(event) => handleOnlyNumber(event)}
                                className={`number-of-ppl-input text-white`}
                                name={`agesF${currentAgeInput.current}`}
                                min="0"
                                data-from={`${a.from}`}
                                data-to={`${a.to}`}
                            />
                        </div>
                    </div>
                ))}
            </Form>
        </Col>
    );
};

export default Regions;

import './styles/main-style.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { loggedAction } from './store/actions';

// Pages
import LoginPage from './pages/login';
import Surveys from './pages/surveys';
import NewSurvey  from './pages/newSurvey';
import Keys from './pages/keys';

class LoginRoute extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Route exact path={`${this.props.path}`} >
				{this.props.logged === true ? <this.props.component /> : <Redirect to="/" />}
			</Route>

		)
	}
}

class PublicRoute extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Route exact path={`${this.props.path}`} >
				{this.props.logged !== true ? <this.props.component /> : <Redirect to="/surveys" />}
			</Route>

		)
	}
}

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		props.loggedAction(props.logged);
	}

	render() {
		return (
			<Router>
				<Switch>
					<LoginRoute logged={this.props.storeLogged} path="/surveys" component={Surveys} />
					<LoginRoute logged={this.props.storeLogged} path="/newSurvey" component={NewSurvey} />
					<LoginRoute logged={this.props.storeLogged} path="/keys" component={Keys}/>
					<PublicRoute logged={this.props.storeLogged} path="/" component={LoginPage}/>
				</Switch>
			</Router>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		storeLogged: state.LoginStore.logged
	}
}

export default connect(mapStateToProps, { loggedAction })(App);

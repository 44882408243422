import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_SURVEYS, DELETE_SURVEY } from './actionType';
import { fetchSurveysSuccess, fetchSurveysError, deleteSurveySuccess, deleteSurveyError } from './action';
import { fetchListSurveysEP, deleteSurveyEP } from '../../../api/survey';

function* fetchSurveysData() {
    try {
        let response = yield call(fetchListSurveysEP);
        if (response) {
            yield put(fetchSurveysSuccess(response));
        } else {
            yield put(fetchSurveysError(response.message));
        }
    } catch (error) {
        yield put(fetchSurveysError(error.message));
    }
}

function* deleteSurveyData(data) {
    try {
        let response = yield call(deleteSurveyEP, data);
        if (response) {
            yield put(deleteSurveySuccess(data.payload));
        } else {
            yield put(deleteSurveyError(response.message));
        }
    } catch (error) {
        yield put(deleteSurveyError(error.message));
    }
}

export function* watchFetchSurveysData() {
    yield takeEvery(FETCH_SURVEYS, fetchSurveysData);
}

export function* watchDeleteSurveyData() {
    yield takeEvery(DELETE_SURVEY, deleteSurveyData);
}

function* surveysSaga() {
    yield all([fork(watchFetchSurveysData), fork(watchDeleteSurveyData)]);
}

export default surveysSaga;

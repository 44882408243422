import { combineReducers } from 'redux';

import LoginStore from './screens/login/reducer';
import SurveysReducer from './screens/surveys/reducer';
import NewSurveyReducer from './screens/newSurvey/reducer';
import KeysReducer from './screens/keys/reducer';

const rootReducer = combineReducers({
   LoginStore,
   
   SurveysReducer,
   NewSurveyReducer,
   KeysReducer
});

export default rootReducer;
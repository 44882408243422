import { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, LOGGED, LOGOUT, LOGOUT_SUCCESS, LOGOUT_ERROR } from './actionType';

const initialState = {
	loading: false,
	success: undefined,
	error: undefined,
	logged: undefined
};

const login = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN:
			return { ...state, loading: true, success: false };
		case LOGIN_SUCCESS:
			return { ...state, loading: false, success: true, logged: true };
		case LOGIN_ERROR:
			return { ...state, loading: false, success: false, logged: false, error: action.payload };
		case LOGGED:
			return { ...state, logged: action.payload };
        case LOGOUT: 
            return { ...state, loading: true }
        case LOGOUT_SUCCESS:
            return { ...state, loading: false, logged: false }
        case LOGOUT_ERROR:
            return { ...state, loading: false, logged: true }
		default:
			return state;
	}
};

export default login;

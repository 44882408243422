import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { FETCH_REGION_LIST, ADD_NEW_SURVEY } from './actionType';
import { fetchRegionListSuccess, fetchRegionListError, addNewSurveySuccess, addNewSurveyError } from './action';

import { fetchRegionListEP } from '../../../api/regions';
import { addNewSurveyEP } from '../../../api/survey';

function* fetchRegionsData() {
    try {
        let response = yield call(fetchRegionListEP);
        if (response) {
            yield put(fetchRegionListSuccess(response));
        } else {
            yield put(fetchRegionListError(response.message));
        }
    } catch (error) {
        yield put(fetchRegionListError(error.message));
    }
}

function* addNewSurveyData(data) {
    try {
        let response = yield call(addNewSurveyEP, data);
        if (response) {
            yield put(addNewSurveySuccess(response.success));
        } else {
            yield put(addNewSurveyError(response.message));
        }
    } catch (error) {
        yield put(addNewSurveyError(error.message));
    }
}

export function* watchFetchRegions() {
    yield takeEvery(FETCH_REGION_LIST, fetchRegionsData);
}

export function* watchAddNewSurvey() {
    yield takeEvery(ADD_NEW_SURVEY, addNewSurveyData);
}

function* newSurveySaga() {
    yield all([fork(watchFetchRegions), fork(watchAddNewSurvey)]);
}

export default newSurveySaga;

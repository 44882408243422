import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

import {
    logout
} from '../store/actions';

const Logout = props => {
    return (
        <Button className="btnLogout bg-grey" onClick={() => props.logout()}>
            Logout
        </Button>
    )
}

const mapStateToProps = (state) => {
    return {
        logged: state.LoginStore.logged
    }
}

export default connect(mapStateToProps, {logout})(Logout);
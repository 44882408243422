import { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, LOGGED, LOGOUT, LOGOUT_SUCCESS, LOGOUT_ERROR } from './actionType';

export const login = (data) => {
	return { type: LOGIN, payload: data };
};

export const loginSuccess = () => {
	return { type: LOGIN_SUCCESS };
};

export const loginError = (message) => {
	return { type: LOGIN_ERROR, payload: message };
};

export const loggedAction = (data) => {
	return { type: LOGGED, payload: data };
};

export const logout = () => {
	return { type: LOGOUT };
};

export const logoutSuccess = () => {
	return { type: LOGOUT_SUCCESS };
};

export const logoutError = () => {
    return { type: LOGOUT_ERROR }
};

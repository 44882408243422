import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { setSelectedSurvey, fetchSurveys, deleteSurvey } from '../store/actions';
import requestFile from '../api/requestFile';
import getRemainingForCurrent from '../api/getRemaining';

import Logout from '../components/logout';
import TrashIcon from '../components/trashIcon';

const Surveys = (props) => {
    const isOpenSummary = useRef(false);
    const [selectedSurvey, setStateSelected] = useState(-1);

    const { allSurvey, selected } = props;

    const handleSelectedSurvey = (index, name) => {
        isOpenSummary.current = true;
        setStateSelected(index);
        props.setSelectedSurvey(name);
    };

    const handleCloseModal = () => {
        isOpenSummary.current = false;
        setStateSelected(-1);
    };

    const checkIsInactive = (from, to) => {
        if (new Date(from) < new Date() && new Date(to) < new Date()) {
            return { trash: false, inactive: true };
        } else if (
            (new Date(from) > new Date() && new Date(to) < new Date()) ||
            (new Date(from) > new Date() && new Date(to) > new Date())
        ) {
            return { trash: true, inactive: true };
        } else {
            return { trash: false, inactive: false };
        }
    };

    useEffect(() => {
        props.fetchSurveys();
    }, []);

    return (
        <React.Fragment>
            <Logout />
            <Container className="bg-white page-wrapper">
                <div className="text-center text-blue font-title mb-4">Lista anketa</div>
                <Row className="justify-content-between">
                    <Col md={7} className="choose-survey-container">
                        {allSurvey &&
                            allSurvey.map((survey, key) => (
                                <Row
                                    key={key}
                                    className={`surveys-list-container w-100 m-0 align-items-center ${
                                        selectedSurvey === key ? 'bg-grey text-white' : ''
                                    }`}>
                                    <div className="ordinal-survey-text">{key + 1}.</div>
                                    <Col xs={8} onClick={() => handleSelectedSurvey(key, survey)}>
                                        <span>{survey.name}</span>
                                    </Col>
                                    {checkIsInactive(survey.from, survey.to).trash && (
                                        <Button outline color="primary" onClick={() => props.deleteSurvey(survey.id)}>
                                            <TrashIcon />
                                        </Button>
                                    )}
                                </Row>
                            ))}
                    </Col>
                    <Col md={'auto'}>
                        <Row className="m-0 w-100 flex-column align-items-center">
                            <Button
                                color="warning"
                                className="new-survey-btn mb-3"
                                onClick={() => props.history.push('/keys')}>
                                Ključevi
                            </Button>
                            <Button
                                color="danger"
                                className="new-survey-btn"
                                onClick={() => props.history.push('/newSurvey')}>
                                Nova anketa
                            </Button>
                        </Row>
                    </Col>
                    <SummaryModal
                        modalOpen={isOpenSummary.current}
                        setModalClose={handleCloseModal}
                        selected={selected}
                        checkIsInactive={checkIsInactive}
                    />
                </Row>
            </Container>
        </React.Fragment>
    );
};

const SummaryModal = (props) => {
    const { selected, checkIsInactive } = props;

    const [errorFile, setErrorFile] = useState(false);
    const [leftInCurrent, setLeftInCurrent] = useState("");


    const handleSelectedSurvey = () => {
        if (selected && selected.townships !== undefined && !checkIsInactive(selected.from, selected.to).inactive) {
            var activeRegion = selected.townships.map((s) => s.regions.map((se, key) => <p key={key}>{se}</p>));
            return activeRegion;
        }
        return 'Inaktivna';
    };

    const downloadSurveyResults = (fileName, isRaw) => {
        requestFile(selected.id, fileName, isRaw, setErrorFile);
    };

    const checkLeft = () => {
        getRemainingForCurrent(setLeftInCurrent);
    };

    useEffect(() => {
        setErrorFile(false);
    }, [props.modalOpen]);

    return (
        <Modal
            isOpen={props.modalOpen}
            toggle={() => {
                props.setModalClose();
            }}>
            <ModalHeader toggle={() => props.setModalClose()} className="border-0">
                {errorFile ? 'Trenutno nije moguće preuzeti fajl, pokušajte kasnije' : 'Rezime'}
            </ModalHeader>
            <ModalBody>
                {handleSelectedSurvey()}
                {leftInCurrent.length > 0 && <div className="left-in-current-survery" dangerouslySetInnerHTML={{__html: leftInCurrent}}></div>}
            </ModalBody>
            <ModalFooter className="border-0 justify-content-start">
                <Button
                    color="secondary"
                    onClick={() => checkLeft()}>
                    Proveri regione
                </Button>
                <Button
                    color="secondary"
                    onClick={() => downloadSurveyResults(`Generisani rezultati - tabelarno - ${selected.name}`, false)}>
                    Generiši rezultate - tabelarno
                </Button>
                <Button
                    color="info"
                    onClick={() =>
                        downloadSurveyResults(`Generisani rezultati - neobrađeni podaci - ${selected.name}`, true)
                    }>
                    Generiši rezultate - neobrađeni podaci
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    if (state.SurveysReducer.initialized) {
        return {
            allSurvey: state.SurveysReducer.surveyList,
            selected: state.SurveysReducer.selectedSurvey
        };
    }
    return {
        allSurvey: state.SurveysReducer.surveyList,
        selected: state.SurveysReducer.selectedSurvey
    };
};

export default connect(mapStateToProps, { setSelectedSurvey, fetchSurveys, deleteSurvey })(withRouter(Surveys));

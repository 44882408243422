import { REGION_ENV } from './env';
let numOfPpl;
if (REGION_ENV() === 'DEV') {
    numOfPpl = {
        '60425b345f142101c808f33f': [
            // Novi Beograd
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 5
            },
            {
                maleNumOfPpl: 12,
                femaleNumOfPpl: 14
            },
            {
                maleNumOfPpl: 12,
                femaleNumOfPpl: 14
            },
            {
                maleNumOfPpl: 12,
                femaleNumOfPpl: 15
            },
            {
                maleNumOfPpl: 15,
                femaleNumOfPpl: 17
            },
            {
                maleNumOfPpl: 17,
                femaleNumOfPpl: 21
            }
        ],
        '60425b3d5f142101c808f340': [
            // Vračar
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 5,
                femaleNumOfPpl: 5
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 5
            }
        ],
        '60425b495f142101c808f341': [
            // Barajevo
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 0
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ],
        '60425b4e5f142101c808f342': [
            // Grocka
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 5,
                femaleNumOfPpl: 5
            },
            {
                maleNumOfPpl: 5,
                femaleNumOfPpl: 5
            },
            {
                maleNumOfPpl: 7,
                femaleNumOfPpl: 6
            },
            {
                maleNumOfPpl: 7,
                femaleNumOfPpl: 7
            }
        ],
        '60425b565f142101c808f343': [
            // Zvezdara
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 10,
                femaleNumOfPpl: 12
            },
            {
                maleNumOfPpl: 12,
                femaleNumOfPpl: 14
            }
        ],
        '60425b5c5f142101c808f344': [
            // Stari Grad
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 5
            }
        ],
        '60425b615f142101c808f345': [
            // Savski Venac
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            }
        ],
        '60425b665f142101c808f346': [
            // Zemun
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 10,
                femaleNumOfPpl: 11
            },
            {
                maleNumOfPpl: 10,
                femaleNumOfPpl: 11
            },
            {
                maleNumOfPpl: 11,
                femaleNumOfPpl: 13
            },
            {
                maleNumOfPpl: 13,
                femaleNumOfPpl: 15
            }
        ],
        '60425b6b5f142101c808f347': [
            // Rakovica
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 6,
                femaleNumOfPpl: 7
            },
            {
                maleNumOfPpl: 6,
                femaleNumOfPpl: 7
            },
            {
                maleNumOfPpl: 6,
                femaleNumOfPpl: 7
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 8
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 10
            }
        ],
        '60425b6e5f142101c808f348': [
            // Sopot
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ],
        '60425b765f142101c808f349': [
            // Surčin
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 4
            }
        ],
        '60425bd05f142101c808f350': [
            // Voždovac Urban
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 7,
                femaleNumOfPpl: 9
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 9
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 11
            },
            {
                maleNumOfPpl: 11,
                femaleNumOfPpl: 13
            }
        ],
        '60425be05f142101c808f351': [
            // Voždovac Rural
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 0
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ],
        '60425bfc5f142101c808f352': [
            // Mladenovac Rural
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ],
        '60425c025f142101c808f353': [
            // Mladenovac Urban
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ],
        '60425c1a5f142101c808f354': [
            // Obrenovac Urban
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ],
        '60425c225f142101c808f355': [
            // Obrenovac Rural
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 4
            }
        ],
        '60425c2e5f142101c808f356': [
            // Palilula Rural
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 5
            },
            {
                maleNumOfPpl: 5,
                femaleNumOfPpl: 5
            }
        ],
        '60425c385f142101c808f357': [
            // Palilula Urban
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 6,
                femaleNumOfPpl: 7
            },
            {
                maleNumOfPpl: 6,
                femaleNumOfPpl: 7
            },
            {
                maleNumOfPpl: 7,
                femaleNumOfPpl: 7
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 9
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 10
            }
        ],
        '60425c455f142101c808f358': [
            // Čukarica Urban
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 9
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 10,
                femaleNumOfPpl: 12
            },
            {
                maleNumOfPpl: 12,
                femaleNumOfPpl: 14
            }
        ],
        '60425c4d5f142101c808f359': [
            // Čukarica Rural
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 0
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 3
            }
        ],
        '60425c605f142101c808f35a': [
            // Lazarevac Rural
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 2
            }
        ],
        '60425c665f142101c808f35b': [
            // Lazarevac Urban
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ]
    };
} else {
    numOfPpl = {
        '60425b345f142101c808f33f': [
            // Novi Beograd
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 5
            },
            {
                maleNumOfPpl: 12,
                femaleNumOfPpl: 14
            },
            {
                maleNumOfPpl: 12,
                femaleNumOfPpl: 14
            },
            {
                maleNumOfPpl: 12,
                femaleNumOfPpl: 15
            },
            {
                maleNumOfPpl: 15,
                femaleNumOfPpl: 17
            },
            {
                maleNumOfPpl: 17,
                femaleNumOfPpl: 21
            }
        ],
        '60425b3d5f142101c808f340': [
            // Vračar
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 5,
                femaleNumOfPpl: 5
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 5
            }
        ],
        '60425b495f142101c808f341': [
            // Barajevo
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 0
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ],
        '60425b4e5f142101c808f342': [
            // Grocka
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 5,
                femaleNumOfPpl: 5
            },
            {
                maleNumOfPpl: 5,
                femaleNumOfPpl: 5
            },
            {
                maleNumOfPpl: 7,
                femaleNumOfPpl: 6
            },
            {
                maleNumOfPpl: 7,
                femaleNumOfPpl: 7
            }
        ],
        '60425b565f142101c808f343': [
            // Zvezdara
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 10,
                femaleNumOfPpl: 12
            },
            {
                maleNumOfPpl: 12,
                femaleNumOfPpl: 14
            }
        ],
        '60425b5c5f142101c808f344': [
            // Stari Grad
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 5
            }
        ],
        '60425b615f142101c808f345': [
            // Savski Venac
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            }
        ],
        '60425b665f142101c808f346': [
            // Zemun
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 10,
                femaleNumOfPpl: 11
            },
            {
                maleNumOfPpl: 10,
                femaleNumOfPpl: 11
            },
            {
                maleNumOfPpl: 11,
                femaleNumOfPpl: 13
            },
            {
                maleNumOfPpl: 13,
                femaleNumOfPpl: 15
            }
        ],
        '60425b6b5f142101c808f347': [
            // Rakovica
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 6,
                femaleNumOfPpl: 7
            },
            {
                maleNumOfPpl: 6,
                femaleNumOfPpl: 7
            },
            {
                maleNumOfPpl: 6,
                femaleNumOfPpl: 7
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 8
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 10
            }
        ],
        '60425b6e5f142101c808f348': [
            // Sopot
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ],
        '60425b765f142101c808f349': [
            // Surčin
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 4
            }
        ],
        '60425bd05f142101c808f350': [
            // Voždovac Urban
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 7,
                femaleNumOfPpl: 9
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 9
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 11
            },
            {
                maleNumOfPpl: 11,
                femaleNumOfPpl: 13
            }
        ],
        '60425be05f142101c808f351': [
            // Voždovac Rural
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 0
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ],
        '60425bfc5f142101c808f352': [
            // Mladenovac Rural
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ],
        '60425c025f142101c808f353': [
            // Mladenovac Urban
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ],
        '60425c1a5f142101c808f354': [
            // Obrenovac Urban
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ],
        '60425c225f142101c808f355': [
            // Obrenovac Rural
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 4
            }
        ],
        '60425c2e5f142101c808f356': [
            // Palilula Rural
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 4
            },
            {
                maleNumOfPpl: 4,
                femaleNumOfPpl: 5
            },
            {
                maleNumOfPpl: 5,
                femaleNumOfPpl: 5
            }
        ],
        '60425c385f142101c808f357': [
            // Palilula Urban
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 6,
                femaleNumOfPpl: 7
            },
            {
                maleNumOfPpl: 6,
                femaleNumOfPpl: 7
            },
            {
                maleNumOfPpl: 7,
                femaleNumOfPpl: 7
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 9
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 10
            }
        ],
        '60425c455f142101c808f358': [
            // Čukarica Urban
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 3
            },
            {
                maleNumOfPpl: 8,
                femaleNumOfPpl: 9
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 9,
                femaleNumOfPpl: 10
            },
            {
                maleNumOfPpl: 10,
                femaleNumOfPpl: 12
            },
            {
                maleNumOfPpl: 12,
                femaleNumOfPpl: 14
            }
        ],
        '60425c4d5f142101c808f359': [
            // Čukarica Rural
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 0
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 3
            }
        ],
        '60425c605f142101c808f35a': [
            // Lazarevac Rural
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 3,
                femaleNumOfPpl: 2
            }
        ],
        '60425c665f142101c808f35b': [
            // Lazarevac Urban
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 1,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 1
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            },
            {
                maleNumOfPpl: 2,
                femaleNumOfPpl: 2
            }
        ]
    };
}
export const predefineAgeNumOfPpl = numOfPpl;

import { all } from 'redux-saga/effects';

//public
import LoginSaga from './screens/login/saga';
import SurveysSaga from './screens/surveys/saga';
import KeysSaga from './screens/keys/saga';
import NewSurveySaga from './screens/newSurvey/saga';

export default function* rootSaga() {
    yield all([
        LoginSaga(),
        SurveysSaga(),
        KeysSaga(),
        NewSurveySaga()
    ]);
}

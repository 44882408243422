import React, { useState, useEffect } from 'react';
import { Container, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    setSurveyName,
    setSurveyFrom,
    setSurveyTo,
    addNewSurvey,
    addNewSurveyError,
    resetSurveyInfo,
    setSelectedRegions,
    updateSelectedRegions,
    removeSelectedRegions,
    fetchRegionList,
    s2AddQuestion,
    s2RemoveQuestion,
    s2AddSubQuestion,
    s2RemoveSubQuestion,
    s2SetQuestionText,
    s2SetQuestionType,
    s2SetSubQuestionText,
    s2SetSecondOptionText,
    s2ToggleSecondOption
} from '../store/actions';

//import components
import Regions from '../components/regions';
import Questions from '../components/questionsList';
import SurveyInfo from '../components/surveyInfo';

const errors = {
    'Survey already exists in this interval': 'Anketa u okviru izabranog intervala već postoji',
    'Bad Request': 'Niste popunili sve podatke za anketu'
};

const NewSurvey = (props) => {
    const [currentStep, setCurrentStep] = useState(0);
    const { name, from, to, selectedRegions, questions, successNewSurvey } = props;

    useEffect(() => {
        props.fetchRegionList();
    }, []);

    useEffect(() => {
        if (successNewSurvey === true) props.history.push('/surveys');
    }, [successNewSurvey]);

    const handleNewSurvey = () => {
        if (name !== undefined && from !== null && to !== null && selectedRegions.length > 0) {
            props.addNewSurvey(name, from, to, [{ name: 'Beograd', regions: selectedRegions }], questions);
            props.resetSurveyInfo();
        } else {
            props.addNewSurveyError('Niste uneli sve podatke!');
        }
    };

    const handleSurveyCancellation = () => {
        props.resetSurveyInfo();
        props.history.push('/surveys');
    };

    const nextStep = (index = null) => {
        var step = index !== null ? index : currentStep + 1;
        // if (
        //     (selectedRegions !== undefined && currentStep === 0) ||
        //     (questions.length > 0 && currentStep === 1) ||
        //     (specialQuestions.length > 0 && currentStep === 2) ||
        //     (name !== undefined && from !== null && to !== null && currentStep === 3)
        // ) {
        setCurrentStep(step);
        // }
    };

    const s2Actions = {
        addQuestion: props.s2AddQuestion,
        removeQuestion: props.s2RemoveQuestion,
        addSubQuestion: props.s2AddSubQuestion,
        removeSubQuestion: props.s2RemoveSubQuestion,
        setQuestionText: props.s2SetQuestionText,
        setQuestionType: props.s2SetQuestionType,
        setSubQuestionText: props.s2SetSubQuestionText,
        toggleSecondOption: props.s2ToggleSecondOption,
        setSecondOptionText: props.s2SetSecondOptionText
    };

    const steps = [
        <Regions {...props} nextStep={nextStep} />,
        <Questions
            {...props}
            nextStep={nextStep}
            actions={s2Actions}
            questionSection={questions}
            title="Lista pitanja"
            bottomText="Informacije o anketi"
            secondOptionEnabled={true}
            selectQuestionTypes={[
                { value: 'OTF', label: 'od 1 do 5' },
                { value: 'YNU', label: 'Da/Ne/Ne znam' },
                { value: 'CM', label: 'Jedan od ponudjenih' },
                { value: 'CA', label: 'Odgovor korisnika' },
            ]}
            keyVal={0}
        />,
        <SurveyInfo {...props} handleNewSurvey={handleNewSurvey} />
    ];

    return (
        <React.Fragment>
            <div className="steps-container">
                {steps.map((s, key) => (
                    <div
                        key={key}
                        className={`step ${key === currentStep ? 'bg-red text-white' : 'bg-grey opacity-5 noselect'}`}
                        onClick={() => nextStep(key)} //currentStep < steps.length - 1 ? currentStep + 1 : currentStep - no back
                    >
                        {key + 1}
                    </div>
                ))}
            </div>

            <Container className="my-4">
                <Button onClick={() => handleSurveyCancellation()}>Poništi anketu</Button>
                {props.errorNewSurvey && (
                    <div className="error-wrapper">
                        {errors[props.errorNewSurvey] !== undefined
                            ? errors[props.errorNewSurvey]
                            : props.errorNewSurvey}
                    </div>
                )}
            </Container>
            {steps[currentStep]}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    if (state.NewSurveyReducer.initialized) {
        return {
            township: state.NewSurveyReducer.township,
            ages: state.NewSurveyReducer.ages,
            name: state.NewSurveyReducer.name,
            from: state.NewSurveyReducer.from,
            to: state.NewSurveyReducer.to,
            selectedRegions: state.NewSurveyReducer.selectedRegions,
            questions: state.NewSurveyReducer.normalQuestions,
            successNewSurvey: state.NewSurveyReducer.successNewSurvey,
            errorNewSurvey: state.NewSurveyReducer.errorNewSurvey
        };
    }
    return {
        township: state.NewSurveyReducer.township,
        ages: state.NewSurveyReducer.ages,
        name: state.NewSurveyReducer.name,
        from: state.NewSurveyReducer.from,
        to: state.NewSurveyReducer.to,
        selectedRegions: state.NewSurveyReducer.selectedRegions,
        questions: state.NewSurveyReducer.normalQuestions,
        successNewSurvey: state.NewSurveyReducer.successNewSurvey,
        errorNewSurvey: state.NewSurveyReducer.errorNewSurvey
    };
};

export default connect(mapStateToProps, {
    setSurveyName,
    setSurveyFrom,
    setSurveyTo,
    addNewSurvey,
    addNewSurveyError,
    resetSurveyInfo,
    setSelectedRegions,
    updateSelectedRegions,
    removeSelectedRegions,
    fetchRegionList,
    s2AddQuestion,
    s2RemoveQuestion,
    s2AddSubQuestion,
    s2RemoveSubQuestion,
    s2SetQuestionText,
    s2SetQuestionType,
    s2SetSubQuestionText,
    s2SetSecondOptionText,
    s2ToggleSecondOption
})(withRouter(NewSurvey));

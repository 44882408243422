import {
    ADD_NEW_SURVEY,
    ADD_NEW_SURVEY_SUCCESS,
    ADD_NEW_SURVEY_ERROR,
    ADD_SURVEY_NAME,
    ADD_SURVEY_FROM,
    ADD_SURVEY_TO,
    RESET_SURVEY_INFO,
    SET_SELECTED_REGIONS,
    UPDATE_SELECTED_REGIONS,
    REMOVE_SELECTED_REGIONS,
    FETCH_REGION_LIST,
    FETCH_REGION_LIST_SUCCESS,
    FETCH_REGION_LIST_ERROR,
    S2_ADD_QUESTION,
    S2_REMOVE_QUESTION,
    S2_ADD_SUBQUESTION,
    S2_REMOVE_SUBQUESTION,
    S2_SET_QUESTION_TEXT,
    S2_SET_QUESTION_TYPE,
    S2_SET_SUBQUESTION_TEXT,
    S2_TOGGLE_SECOND_OPTION,
    S2_SET_SECOND_OPTION_TEXT,
    S3_ADD_QUESTION,
    S3_REMOVE_QUESTION,
    S3_ADD_SUBQUESTION,
    S3_REMOVE_SUBQUESTION,
    S3_SET_QUESTION_TEXT,
    S3_SET_QUESTION_TYPE,
    S3_SET_SUBQUESTION_TEXT,
    S3_TOGGLE_SECOND_OPTION,
    S3_SET_SECOND_OPTION_TEXT,
} from './actionType';

export const fetchRegionList = () => {
    return { type: FETCH_REGION_LIST };
};

export const fetchRegionListSuccess = (data) => {
    return { type: FETCH_REGION_LIST_SUCCESS, payload: data };
};

export const fetchRegionListError = (data) => {
    return { type: FETCH_REGION_LIST_ERROR, payload: data };
};

export const setSurveyName = (name) => {
    return { type: ADD_SURVEY_NAME, payload: name };
};

export const setSurveyFrom = (from) => {
    return { type: ADD_SURVEY_FROM, payload: from };
};

export const setSurveyTo = (to) => {
    return { type: ADD_SURVEY_TO, payload: to };
};

export const setSelectedRegions = (regions) => {
    return {
        type: SET_SELECTED_REGIONS,
        payload: {
            regions: regions
        }
    };
};

export const updateSelectedRegions = (region, ageGroups) => {
    return {
        type: UPDATE_SELECTED_REGIONS,
        payload: {
            r: region,
            age: ageGroups
        }
    };
};

export const removeSelectedRegions = (region) => {
    return {
        type: REMOVE_SELECTED_REGIONS,
        payload: region
    };
};

export const resetSurveyInfo = () => {
    return { type: RESET_SURVEY_INFO };
};

export const s2AddQuestion = () => {
    return { type: S2_ADD_QUESTION };
};
export const s2RemoveQuestion = (question, index) => {
    return { type: S2_REMOVE_QUESTION, payload: { question, index } };
};
export const s2SetQuestionText = (text, section) => {
    return { type: S2_SET_QUESTION_TEXT, payload: { text, section } };
};
export const s2SetQuestionType = (type, section) => {
    return { type: S2_SET_QUESTION_TYPE, payload: { type, section } };
};
export const s2SetSubQuestionText = (text, section, index) => {
    return { type: S2_SET_SUBQUESTION_TEXT, payload: { text, section, index } };
};
export const s2AddSubQuestion = (section) => {
    return { type: S2_ADD_SUBQUESTION, payload: section };
};
export const s2RemoveSubQuestion = (section, index) => {
    return { type: S2_REMOVE_SUBQUESTION, payload: { section, index } };
};
export const s2ToggleSecondOption = (section) => {
    return { type: S2_TOGGLE_SECOND_OPTION, payload: section };
};
export const s2SetSecondOptionText = (text, section) => {
    return { type: S2_SET_SECOND_OPTION_TEXT, payload: { text, section } };
};

export const s3AddQuestion = () => {
    return { type: S3_ADD_QUESTION };
};
export const s3RemoveQuestion = (question, index) => {
    return { type: S3_REMOVE_QUESTION, payload: { question, index } };
};
export const s3SetQuestionText = (text, section) => {
    return { type: S3_SET_QUESTION_TEXT, payload: { text, section } };
};
export const s3SetQuestionType = (type, section) => {
    return { type: S3_SET_QUESTION_TYPE, payload: { type, section } };
};
export const s3SetSubQuestionText = (text, section, index) => {
    return { type: S3_SET_SUBQUESTION_TEXT, payload: { text, section, index } };
};
export const s3AddSubQuestion = (section) => {
    return { type: S3_ADD_SUBQUESTION, payload: section };
};
export const s3RemoveSubQuestion = (section, index) => {
    return { type: S3_REMOVE_SUBQUESTION, payload: { section, index } };
};
export const s3ToggleSecondOption = (section) => {
    return { type: S3_TOGGLE_SECOND_OPTION, payload: section };
};
export const s3SetSecondOptionText = (text, section) => {
    return { type: S3_SET_SECOND_OPTION_TEXT, payload: { text, section } };
};

export const addNewSurvey = (name, from, to, townships, questionGroups, specialQuestionGroups) => {
    return {
        type: ADD_NEW_SURVEY,
        payload: {
            name,
            from,
            to,
            townships,
            questionGroups,
            specialQuestionGroups
        }
    };
};

export const addNewSurveySuccess = (data) => {
    return { type: ADD_NEW_SURVEY_SUCCESS, payload: data };
};

export const addNewSurveyError = (data) => {
    return { type: ADD_NEW_SURVEY_ERROR, payload: data };
};

import axios from 'axios';

import { API_URL } from '../env';
// import request from './request';

const requestFile = (data, fileName, isRaw, setError) => {
    axios({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true,

        url: `${API_URL()}/file/results${isRaw ? 'Raw' : ''}?id=${data}`,
        responseType: 'blob'
    })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            setError(error);
        });
};

export default requestFile;

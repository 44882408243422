import {
    ADD_NEW_SURVEY,
    ADD_NEW_SURVEY_SUCCESS,
    ADD_NEW_SURVEY_ERROR,
    ADD_SURVEY_NAME,
    ADD_SURVEY_FROM,
    ADD_SURVEY_TO,
    RESET_SURVEY_INFO,
    SET_SELECTED_REGIONS,
    UPDATE_SELECTED_REGIONS,
    REMOVE_SELECTED_REGIONS,
    FETCH_REGION_LIST,
    FETCH_REGION_LIST_SUCCESS,
    FETCH_REGION_LIST_ERROR,
    S2_ADD_QUESTION,
    S2_REMOVE_QUESTION,
    S2_ADD_SUBQUESTION,
    S2_REMOVE_SUBQUESTION,
    S2_SET_QUESTION_TEXT,
    S2_SET_QUESTION_TYPE,
    S2_SET_SUBQUESTION_TEXT,
    S2_TOGGLE_SECOND_OPTION,
    S2_SET_SECOND_OPTION_TEXT,
    S3_ADD_QUESTION,
    S3_REMOVE_QUESTION,
    S3_ADD_SUBQUESTION,
    S3_REMOVE_SUBQUESTION,
    S3_SET_QUESTION_TEXT,
    S3_SET_QUESTION_TYPE,
    S3_SET_SUBQUESTION_TEXT,
    S3_TOGGLE_SECOND_OPTION,
    S3_SET_SECOND_OPTION_TEXT
} from './actionType';
// import { township } from '../../../data/fake-data';

const initialState = {
    initialized: false,
    loading: false,
    error: undefined,

    successNewSurvey: undefined,
    errorNewSurvey: undefined,

    township: undefined,
    ages: [
        {
            from: 18,
            to: 24
        },
        {
            from: 25,
            to: 34
        },
        {
            from: 35,
            to: 44
        },
        {
            from: 45,
            to: 54
        },
        {
            from: 55,
            to: 64
        },
        {
            from: 65
        }
    ],

    name: undefined,
    from: null,
    to: null,

    selectedRegions: [],
    normalQuestions: [
        {
            questionTitle: '',
            questionType: undefined,
            secondOption: false,
            secondOptionTitle: undefined,
            questions: [
                {
                    questionText: ''
                }
            ]
        }
    ]
};

const newSurveys = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REGION_LIST:
            return { ...state, loading: true };
        case FETCH_REGION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                initialized: true,
                township: action.payload
            };
        case FETCH_REGION_LIST_ERROR:
            return { ...state, loading: false, error: action.payload };
        case ADD_SURVEY_NAME:
            return { ...state, name: action.payload };
        case ADD_SURVEY_FROM:
            if (state.to < action.payload) return { ...state, from: action.payload, to: action.payload };
            return { ...state, from: action.payload };
        case ADD_SURVEY_TO:
            if (state.from < action.payload) return { ...state, to: action.payload };
            else return { ...state, from: action.payload, to: action.payload };
        case ADD_NEW_SURVEY:
            return {
                ...state
            };
        case ADD_NEW_SURVEY_SUCCESS:
            return {
                ...state,
                loading: false,
                successNewSurvey: action.payload
            };
        case ADD_NEW_SURVEY_ERROR:
            return {
                ...state,
                loading: false,
                errorNewSurvey: action.payload
            };
        case SET_SELECTED_REGIONS:
            return {
                ...state,
                selectedRegions: [...state.selectedRegions, action.payload.regions]
            };
        case UPDATE_SELECTED_REGIONS: {
            var index = state.selectedRegions.findIndex((item, i) => {
                return item.region === action.payload.r;
            });
            return {
                ...state,
                selectedRegions: state.selectedRegions.map((region, i) =>
                    i === index ? { ...region, ageGroups: action.payload.age } : region
                )
            };
        }
        case REMOVE_SELECTED_REGIONS:
            return {
                ...state,
                selectedRegions: state.selectedRegions.filter((e) => e.region !== action.payload)
            };
        case RESET_SURVEY_INFO:
            return {
                ...state,
                name: undefined,
                from: null,
                to: null,
                selectedRegions: [],
                normalQuestions: [
                    {
                        questionTitle: '',
                        questionType: undefined,
                        secondOption: false,
                        questions: [
                            {
                                questionText: ''
                            }
                        ]
                    }
                ],
                errorNewSurvey: undefined
            };
        case S2_ADD_QUESTION:
            return {
                ...state,
                normalQuestions: [
                    ...state.normalQuestions,
                    {
                        questionTitle: '',
                        questionType: undefined,
                        secondOption: false,
                        questions: [
                            {
                                questionText: ''
                            }
                        ]
                    }
                ]
            };
        case S2_REMOVE_QUESTION: {
            let questions = state.normalQuestions;
            questions.splice(action.payload.index, 1);
            return {
                ...state,
                normalQuestions: [...questions]
            };
        }
        case S2_ADD_SUBQUESTION: {
            let questions = state.normalQuestions;
            questions[action.payload].questions.push({ questionText: '' });
            return {
                ...state,
                normalQuestions: [...questions]
            };
        }
        case S2_REMOVE_SUBQUESTION: {
            let questions = state.normalQuestions;
            questions[action.payload.section].questions.splice(action.payload.index, 1);
            return {
                ...state,
                normalQuestions: [...questions]
            };
        }
        case S2_SET_QUESTION_TEXT: {
            let questions = state.normalQuestions;
            questions[action.payload.section].questionTitle = action.payload.text;
            return {
                ...state,
                normalQuestions: [...questions]
            };
        }
        case S2_SET_QUESTION_TYPE: {
            let questions = state.normalQuestions;
            questions[action.payload.section].questionType = action.payload.type;
            if (action.payload.type !== 'CM') {
                questions[action.payload.section].secondOption = false;
                questions[action.payload.section].secondOptionTitle = undefined;
            }
            return {
                ...state,
                normalQuestions: [...questions]
            };
        }
        case S2_SET_SUBQUESTION_TEXT: {
            let questions = state.normalQuestions;
            questions[action.payload.section].questions[action.payload.index].questionText = action.payload.text;
            return {
                ...state,
                normalQuestions: [...questions]
            };
        }
        case S2_TOGGLE_SECOND_OPTION: {
            let questions = state.normalQuestions;
            questions[action.payload].secondOption = !questions[action.payload].secondOption;
            if (questions[action.payload].secondOption === false) {
                questions[action.payload].secondOptionTitle = undefined;
            }
            return {
                ...state,
                normalQuestions: [...questions]
            };
        }
        case S2_SET_SECOND_OPTION_TEXT: {
            let questions = state.normalQuestions;
            questions[action.payload.section].secondOptionTitle = action.payload.text;
            return {
                ...state,
                normalQuestions: [...questions]
            };
        }
        case S3_ADD_QUESTION:
            return {
                ...state,
                specialQuestions: [
                    ...state.specialQuestions,
                    {
                        questionTitle: '',
                        questionType: undefined,
                        secondOption: false,
                        questions: [
                            {
                                questionText: ''
                            }
                        ]
                    }
                ]
            };
        case S3_REMOVE_QUESTION: {
            let questions = state.specialQuestions;
            questions.splice(action.payload.index, 1);
            return {
                ...state,
                specialQuestions: [...questions]
            };
        }
        case S3_ADD_SUBQUESTION: {
            let questions = state.specialQuestions;
            questions[action.payload].questions.push({ questionText: '' });
            return {
                ...state,
                specialQuestions: [...questions]
            };
        }
        case S3_REMOVE_SUBQUESTION: {
            let questions = state.specialQuestions;
            questions[action.payload.section].questions.splice(action.payload.index, 1);
            return {
                ...state,
                specialQuestions: [...questions]
            };
        }
        case S3_SET_QUESTION_TEXT: {
            let questions = state.specialQuestions;
            questions[action.payload.section].questionTitle = action.payload.text;
            return {
                ...state,
                specialQuestions: [...questions]
            };
        }
        case S3_SET_QUESTION_TYPE: {
            let questions = state.specialQuestions;
            questions[action.payload.section].questionType = action.payload.type;
            if (action.payload.type !== 'CM') {
                questions[action.payload.section].secondOption = false;
                questions[action.payload.section].secondOptionTitle = undefined;
            }
            return {
                ...state,
                specialQuestions: [...questions]
            };
        }
        case S3_SET_SUBQUESTION_TEXT: {
            let questions = state.specialQuestions;
            questions[action.payload.section].questions[action.payload.index].questionText = action.payload.text;
            return {
                ...state,
                specialQuestions: [...questions]
            };
        }
        case S3_TOGGLE_SECOND_OPTION: {
            let questions = state.specialQuestions;
            questions[action.payload].secondOption = !questions[action.payload].secondOption;
            if (questions[action.payload].secondOption === false) {
                questions[action.payload].secondOptionTitle = undefined;
            }
            return {
                ...state,
                specialQuestions: [...questions]
            };
        }
        case S3_SET_SECOND_OPTION_TEXT: {
            let questions = state.specialQuestions;
            questions[action.payload.section].secondOptionTitle = action.payload.text;
            return {
                ...state,
                specialQuestions: [...questions]
            };
        }
        default:
            return state;
    }
};

export default newSurveys;

export const ADD_NEW_SURVEY = 'ADD_NEW_SURVEY';
export const ADD_NEW_SURVEY_SUCCESS = 'ADD_NEW_SURVEY_SUCCESS';
export const ADD_NEW_SURVEY_ERROR = 'ADD_NEW_SURVEY_ERROR';

export const ADD_SURVEY_NAME = 'ADD_SURVEY_NAME';
export const ADD_SURVEY_FROM = 'ADD_SURVEY_FROM';
export const ADD_SURVEY_TO = 'ADD_SURVEY_TO';

export const SET_SELECTED_REGIONS = 'SET_SELECTED_REGIONS';
export const UPDATE_SELECTED_REGIONS = 'UPDATE_SELECTED_REGIONS';
export const REMOVE_SELECTED_REGIONS = 'REMOVE_SELECTED_REGIONS';

export const RESET_SURVEY_INFO = 'RESET_SURVEY_INFO';

export const FETCH_REGION_LIST = 'FETCH_REGION_LIST';
export const FETCH_REGION_LIST_SUCCESS = 'FETCH_REGION_LIST_SUCCESS';
export const FETCH_REGION_LIST_ERROR = 'FETCH_REGION_LIST_ERROR';

export const S2_ADD_QUESTION = 'S2_ADD_QUESTION';
export const S2_REMOVE_QUESTION = 'S2_REMOVE_QUESTION';
export const S2_ADD_SUBQUESTION = 'S2_ADD_SUBQUESTION';
export const S2_REMOVE_SUBQUESTION = 'S2_REMOVE_SUBQUESTION';
export const S2_SET_QUESTION_TEXT = 'S2_SET_QUESTION_TEXT';
export const S2_SET_SUBQUESTION_TEXT = 'S2_SET_SUBQUESTION_TEXT';
export const S2_SET_QUESTION_TYPE = 'S2_SET_QUESTION_TYPE';
export const S2_TOGGLE_SECOND_OPTION = 'S2_TOGGLE_SECOND_OPTION'
export const S2_SET_SECOND_OPTION_TEXT = 'S2_SECOND_OPTION_TEXT'

export const S3_ADD_QUESTION = 'S3_ADD_QUESTION';
export const S3_REMOVE_QUESTION = 'S3_REMOVE_QUESTION';
export const S3_ADD_SUBQUESTION = 'S3_ADD_SUBQUESTION';
export const S3_REMOVE_SUBQUESTION = 'S3_REMOVE_SUBQUESTION';
export const S3_SET_QUESTION_TEXT = 'S3_SET_QUESTION_TEXT';
export const S3_SET_SUBQUESTION_TEXT = 'S3_SET_SUBQUESTION_TEXT';
export const S3_SET_QUESTION_TYPE = 'S3_SET_QUESTION_TYPE';
export const S3_TOGGLE_SECOND_OPTION = 'S3_TOGGLE_SECOND_OPTION'
export const S3_SET_SECOND_OPTION_TEXT = 'S3_SECOND_OPTION_TEXT'
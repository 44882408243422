import { takeEvery, fork, put, all, call} from 'redux-saga/effects';

import { LOGIN, LOGOUT } from './actionType';
import {
    loginSuccess,
    loginError, 
    logoutSuccess,
    logoutError
} from './action';
import { loginEP } from '../../../api/auth';
import { getLogout } from '../../../api/logout';

function* loginData({ payload }) {
    try {
        let response = yield call(loginEP, payload);
        if (response) {
            yield put(loginSuccess());
        } else {
            yield put(loginError(response.message));
        }
    } catch (error) {
        yield put(loginError(error.message));
    }
}

function* logoutData() {
    try {
        let response = yield call(getLogout);
        if(response) {
            yield put(logoutSuccess());
        } else {
            yield put(logoutError());
        }
    } catch(error) {
        yield put(logoutError());
    }
}

export function* watchLoginData() {
    yield takeEvery(LOGIN, loginData);
}

export function* watchLogoutData() {
    yield takeEvery(LOGOUT, logoutData);
}

function* loginSaga() {
    yield all([fork(watchLoginData), fork(watchLogoutData)]);
}

export default loginSaga;
import request from './request';
import { API_URL, MAIN_ADMIN_KEY } from '../env';

const getRemainingForCurrent = (setData) => {
    request({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'api-key': '123.main.admin.key'
        },
        withCredentials: true,
        url: `${API_URL()}/survey/current`
    })
        .then((response) => {
            let text = '';
            let leftRegions = response.townships[0].regions;
            for (let i = 0; i < leftRegions.length; i++) {
                text += leftRegions[i].region.name;
                text += `<br/><span class="${
                    didItFinishForRegion(leftRegions[i].ageGroups[0]) ? 'green' : 'red'
                }">18 - 24 M: ${leftRegions[i].ageGroups[0].curMaleNumOfPpl}/${
                    leftRegions[i].ageGroups[0].maleNumOfPpl
                } Ž: ${leftRegions[i].ageGroups[0].curFemaleNumOfPpl}/${
                    leftRegions[i].ageGroups[0].femaleNumOfPpl
                }</span>`;
                text += `<br/><span class="${
                    didItFinishForRegion(leftRegions[i].ageGroups[1]) ? 'green' : 'red'
                }">25 - 34 M: ${leftRegions[i].ageGroups[1].curMaleNumOfPpl}/${
                    leftRegions[i].ageGroups[1].maleNumOfPpl
                } Ž: ${leftRegions[i].ageGroups[1].curFemaleNumOfPpl}/${
                    leftRegions[i].ageGroups[1].femaleNumOfPpl
                }</span>`;
                text += `<br/><span class="${
                    didItFinishForRegion(leftRegions[i].ageGroups[2]) ? 'green' : 'red'
                }">35 - 44 M: ${leftRegions[i].ageGroups[2].curMaleNumOfPpl}/${
                    leftRegions[i].ageGroups[2].maleNumOfPpl
                } Ž: ${leftRegions[i].ageGroups[2].curFemaleNumOfPpl}/${
                    leftRegions[i].ageGroups[2].femaleNumOfPpl
                }</span>`;
                text += `<br/><span class="${
                    didItFinishForRegion(leftRegions[i].ageGroups[3]) ? 'green' : 'red'
                }">45 - 54 M: ${leftRegions[i].ageGroups[3].curMaleNumOfPpl}/${
                    leftRegions[i].ageGroups[3].maleNumOfPpl
                } Ž: ${leftRegions[i].ageGroups[3].curFemaleNumOfPpl}/${
                    leftRegions[i].ageGroups[3].femaleNumOfPpl
                }</span>`;
                text += `<br/><span class="${
                    didItFinishForRegion(leftRegions[i].ageGroups[4]) ? 'green' : 'red'
                }">55 - 64 M: ${leftRegions[i].ageGroups[4].curMaleNumOfPpl}/${
                    leftRegions[i].ageGroups[4].maleNumOfPpl
                } Ž: ${leftRegions[i].ageGroups[4].curFemaleNumOfPpl}/${
                    leftRegions[i].ageGroups[4].femaleNumOfPpl
                }</span>`;
                text += `<br/><span class="${
                    didItFinishForRegion(leftRegions[i].ageGroups[5]) ? 'green' : 'red'
                }">65+ M: ${leftRegions[i].ageGroups[5].curMaleNumOfPpl}/${
                    leftRegions[i].ageGroups[5].maleNumOfPpl
                } Ž: ${leftRegions[i].ageGroups[5].curFemaleNumOfPpl}/${
                    leftRegions[i].ageGroups[5].femaleNumOfPpl
                }</span><br/><br/>`;
            }
            // console.log(response);
            // console.log(text);
            setData(text);
        })
        .catch((error) => {
            alert(error);
        });
};

function didItFinishForRegion(ageGroup) {
    if (ageGroup.curMaleNumOfPpl >= ageGroup.maleNumOfPpl && ageGroup.curFemaleNumOfPpl >= ageGroup.femaleNumOfPpl)
        return true;
    return false;
}

export default getRemainingForCurrent;

import  request from './request';
import { API_URL } from "../env";
export const fetchListKeysEP = () => {
    return request({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        url: `${API_URL()}/auth/apikey/list`
    });
};

export const fetchNewKeyEP = (data) => {
    return request({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        url: `${API_URL()}/auth/apikey/create`,
        data: {
            name: data.payload.name,
            apiKey: data.payload.apiKey
        }
    });
};

export const deleteKeyEP = (data) => {
    return request({
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        url: `${API_URL()}/auth/apikey/delete/` + data.payload
    })
}
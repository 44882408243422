import  request from './request';
import {API_URL} from '../env'

export const loginEP = (data) => {
    return request({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        url: `${API_URL()}/auth/login`,
        data: data
    });
};